import React, { useContext, useEffect, useState } from "react"
import Hero from "../components/Hero"
import { graphql } from "gatsby"
import NewsListing from "../components/NewsListing"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { StateContext } from "../helpers/StateProvider"

const News = props => {
  const { authIsReady } = useAuthContext()
  const { language, setLanguage } = useContext(StateContext)
  const [blog, setBlog] = useState()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    console.log("loading")
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    }
    fetch(
      "https://www.googleapis.com/blogger/v3/blogs/5833795409785926601/posts?key=AIzaSyBdZwEnmytFEWrcd2WrNODWWe8nU76OepQ",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        console.log("blog as result", blog)
        setBlog(result)
      })
      .catch(error => console.log("error", error))
    setLoading(false)
  }, [])

  return (
    <>
      {authIsReady && (
        <>
          <Seo title="news" />
          <Hero
            arabic={language}
            title={
              language == false
                ? "DOT VIP News and Tutorials"
                : "أخبار ودروس DOT VIP"
            }
            subTitle={
              language == false
                ? "Follow this section to learn more about our updates, new features, and services"
                : "اتبع هذا القسم لمعرفة المزيد حول التحديثات والميزات الجديدة والخدمات"
            }
          />
          <NewsListing news={blog} arabic={language} />
        </>
      )}
    </>
  )
}

export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    allNews(limit: 6) {
      edges {
        node {
          description
          featured_image
          id
          overview
          title
          topic_title
          topic
          created_at
          localImage {
            childImageSharp {
              fixed(width: 300, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

export default News
