import { useState, useEffect } from "react";
import { collection, query, where, getDocs,limit } from "firebase/firestore";
import { db } from "../firebase/config"
const useNewsCount = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchWidget = async () => {
        const table=[];
        const q = query(collection(db, "news"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
           table.push(doc.data())

        });
        setData(table);
      }
  
      fetchWidget()
  }, []);

  return data;
};

export default useNewsCount;