import styled from "styled-components";

export const Container = styled.div`
    padding: 30px 0;

    .container{
        display: flex;
        align-items: center;
        gap:10px;
        flex-wrap: wrap;

        article{
            width: 32%;

            @media (max-width:992px){
                width: 49%;
            }

            @media (max-width: 523px){
                width: 100%;
            }
        }
    }

    .load-more{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        width: 100%;

        button{
            background: none;
            border: 1px solid var(--lightGreen);
            padding: 5px 10px;
            color: var(--lightGreen);
            border-radius: 5px;
            display: flex;
            align-items: center;
            gap: 5px;

            span.icon{
                width: 13px;
            }
        }
    }
`;