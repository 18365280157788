import React, { useState, useEffect } from "react"

import { Container } from "./NewsListing.styles"
import NewsItem from "../GlobalComponents/NewsItem"

import ArrowDown from "../../assets/icons/arrow-down.svg"

import useNews from "../../hooks/useNews"

import useNew from "../../hooks/useNew"
import useNewsCount from "../../hooks/useNewsCount"
const NewsListing = props => {
  const arabic = props.arabic
  const newsData = props.news
  const [loading, setLoading] = useState(true)
  const lenght = useNewsCount()
  const [load, setLoad] = useState(null)
  const [limits, setLimits] = useState(6)
  const data = useState([]) //useNew(limits)

  useEffect(() => {
    setLoading(false)
    console.log("news data is ", newsData)
  }, [newsData])
  const action = () => {
    setLoad("true")
    setLimits(limits + 6)
  }

  return (
    <Container>
      <div className="container">
        {!load &&
          newsData &&
          newsData.items.map(record => {
            console.log("newdata", record)
            return (
              <NewsItem
                key={record.title}
                lenght={lenght}
                type="qr"
                record={record}
                arabic={arabic}
              />
            )
          })}
        {data.length > 0 &&
          data?.items?.map(record => {
            console.log("data is ", record)
            return (
              <NewsItem
                key={record.title}
                lenght={lenght}
                record={record}
                id={record.id}
                type="local"
                arabic={arabic}
              />
            )
          })}
      </div>
      {/*<div className="load-more">
        {lenght && data && lenght.length !== data.length && (
          <button>
            <span className="icon">
              <img src={ArrowDown} alt="arrow down" />
            </span>

            <span className="text" onClick={action}>
              {arabic == false ? "Load More" : "تحميل المزيد"}
            </span>
          </button>
        )}
        </div>*/}
      <div style={{ height: "100px" }}>&nbsp;</div>
    </Container>
  )
}

export default NewsListing
